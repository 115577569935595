import React from 'react';
import './App.scss';
import Obfuscate from 'react-obfuscate';
import logo from './assets/img/sabo_logo.svg';

const App = () => {
    return (
        <div className="grid-x align-center-middle">
            <div className="small-11 cell text-center">
                <img src={logo} alt="Sabo Ambulante Psychiatrische Betreuung" className="logo"/>
                <p><b>Sabo Ambulante Psychiatrische Betreuung</b><br/> Sabine Bolliger, Seilerwis 5, 8606 Greifensee</p>
                <p>
                    <b>E-Mail:</b> <Obfuscate email="apb@sa-bo.ch" linkText="Netter Versuch, Spammer" /><br/> <b>Telefon:</b> <Obfuscate tel="+41 79 127 55 05" /><br/>
                    <Obfuscate href="https://m.me/apbsabo" obfuscateChildren={false}>fb Messenger Chat</Obfuscate> &middot; <Obfuscate href="https://wa.me/0041791275505" obfuscateChildren={false}>WhatsApp Chat</Obfuscate>
                </p>
                <p><b>UID:</b> CHE-134.354.815 &middot; <b>ZSR Nr.:</b> V423731</p>
            </div>
        </div>
    );
};

export default App;